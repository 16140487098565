import React from "react";
import About from "sections/About/About";
import { useEngAboutData } from "lang/en/about";

const AboutPage = () => {
  const data = useEngAboutData();
  return <About data={data} />;
};

export default AboutPage;
