import { useAboutPhotos } from "shared/Hooks/about/useAboutPhotos";
import React from "react";

export const useEngAboutData = () => {
  const photos = useAboutPhotos();
  return {
    metaInfo: {
      title: "About Us",
      ogDescription:
        "We provide innovative solutions for the industrial market. We offer products from reputable manufacturers and our services are highly appreciated by many companies. Check us out!",
    },
    hero: {
      title: "About Us",
      subtitle:
        "We provide innovative solutions for the industrial market. We offer products from reputable manufacturers and our services are highly appreciated by many companies. Check us out!",
    },
    ourCompany: {
      title: "Our company",
      subtitle:
        "We're distinguished by a young, dynamic team and constant desire to develop, striving to consistently provide new solutions and tools to our clients. We support industry by offering help in use, tool selection and technology development. We also provide tool management solutions and business management advice.",
      src: photos.img1,
      bigImg: true,
    },
    ourOffer: {
      title: "Our offer",
      subtitle:
        "We supply products from reputable producers who are global industry leaders: Kennametal, Haimer, Walter and others. Their products are known for high quality, precision and durability. To complement this, the ToolBOSS machines in our assortment greatly simplify work automation, allowing you to save on admin costs or new tools.",
      src: photos.img2,
      bigImg: true,
    },
    trust: {
      title: "Professionalism and trust",
      subtitle:
        "We're a reliable partner, always trying to best forecast our clients' needs and exceed their expectations. Our company's grown rapidly, gaining the trust of more and more business partners, as well as their respect. We're focused on continuous development and being able to serve industry with optimal solutions. We help at every stage of the contract, providing advice and assistance from negotiations to contract finalization.",
      src: photos.img3,
      bigImg: true,
    },
    weHelp: {
      title: "Business support",
      subtitle:
        "We help both during both the selection phase and tech development, as well as their implementation. We're continually improving our core competencies to ensure that our expert advice stays on the cutting edge. We're industry professionals and our guidance guarantees you have unparalleled help, advice and service. We specialize in servicing all types of industry, providing optimal solutions for metalworking.",
      src: photos.img4,
      bigImg: true,
    },
    bottomSection: {
      title: (
        <>
          Our company is a partner you can rely on,
          <br />
          and together with us we’ll achieve your business goals faster.
        </>
      ),
    },
  };
};
